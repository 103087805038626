import { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import Stack from '@mui/material/Stack';

import Grid from '@mui/material/Unstable_Grid2';

import Iconify from 'src/components/iconify';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import {
  Avatar,
  Button,
  Card,
  IconButton,
  ListItemText,
  Rating,
  Skeleton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import axios from 'axios';
import DisplayBusinessSubcategories from '../display-business-subcategories';
import { Box, Container } from '@mui/system';
import moment from 'moment-timezone';
import { m } from 'framer-motion';
import DownloadButtons from 'src/sections/timezzi-Landing-Page/download_buttons';
import { varFade } from 'src/components/animate';
import ServiceCards from './business-services-view';

// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function BUisnessPublicView() {
  const [branch, setbranch] = useState(null);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const { socialLinks, avialableSlots = [] } = branch ?? {};
  const [currentTab, setCurrentTab] = useState(0);
  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);
  const fetchBranch = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_HOST_API}/business/public/availbilties/${id}?timezone=${timeZone}`,
        {}
      );
      const { body } = res.data;
      setLoading(false);
      setbranch(body);
    } catch (error) {
      setLoading(false);
      console.error('There was an error making the request:', error);
    }
  };
  useEffect(() => {
    if (!branch) {
      fetchBranch();
    }
  }, [id]);
  return (
    <Container
      sx={{
        pt: 15,
        pb: 10,
        minHeight: 1,
      }}
    >
      <Grid container spacing={3}>
        <Grid
          xs={24}
          md={24}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: (theme) => theme.spacing(2, 3, 3, 3),
          }}
        >
          <Stack sx={{ mb: 2 }}  direction="column" spacing={2}>
            {/* <Avatar
              alt="image"
              src={branch?.image}
              sx={{ marginTop: '.5rem', width: 56, height: 56 }}
            /> */}
            <Typography variant="h3">
              {branch?.name}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Rating value={branch?.averageRating ||0} size="large" readOnly  /> 
            <Typography color={'primary'} variant="h5" sx={{ ml: 1 }}>({branch?.totalReviews || 0}) </Typography>
            </Box>
          </Stack>
          <Box
            sx={{
              width: '100%',
              height: '300px',
              backgroundImage: `url(${branch?.image})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              // backgroundRepeat: 'no-repeat',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 2,
            }}
          >
            {/* <Typography
              variant="h3"
              align="center"
              sx={{
                color: 'white',
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                padding: '0.5rem 1rem',
                borderRadius: '8px',
              }}
            >
              {branch?.name}
            </Typography> */}
          </Box>
          <Typography variant="h5" gutterBottom>
            About
          </Typography>
          <Card>
            <Typography sx={{ p: 2 }} variant="body2" color="text.secondary">
              {branch?.description || 'This business has not provided information yet.'}
            </Typography>
          </Card>
          <Typography color="info" variant="h5" sx={{ mb: 2,mt:1 }}>
            Services
          </Typography>
          {loading ? (
            <Skeleton variant="rounded" maxWidth={'100%'} height={'6rem'} />
          ) : branch?.services?.length ? (
            <ServiceCards services={branch?.services} />
          ) : (
            <Card>
              <Typography sx={{ p: 2 }} variant="body2" color="text.secondary">
                {'This business have not provided services yet.'}
              </Typography>
            </Card>
          )}{' '}
        </Grid>
        <Grid container spacing={2}>
          {/* About Section */}
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: (theme) => theme.spacing(2, 3, 3, 3),
            }}
          >
            <Typography color="info" variant="h5" sx={{ mb: 2 }}>
              Information
            </Typography>
            {loading ? (
              <Stack spacing={2}>
                {/* Skeleton Loaders */}
                {[...Array(6)].map((_, index) => (
                  <Skeleton key={index} variant="rounded" width="100%" height="2rem" />
                ))}
              </Stack>
            ) : (
              <Stack spacing={2}>
                {/* Contact Number */}
                <Item sx={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip title="Contact us on WhatsApp" arrow>
                    <a
                      href={`https://wa.me/${branch?.contactNumber}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Iconify icon="solar:call-chat-rounded-bold-duotone" sx={{ mr: 2 }} />
                      <Typography>{branch?.contactNumber}</Typography>
                    </a>
                  </Tooltip>
                </Item>

                {/* Social Links */}
                {[
                  { icon: 'devicon:facebook', link: socialLinks?.facebook, label: 'facebook' },
                  {
                    icon: 'skill-icons:instagram',
                    link: socialLinks?.instagram,
                    label: 'instagram',
                  },
                  // { icon: 'logos:twitter', link: socialLinks?.twitter, label: 'twitter' },
                  { icon: 'devicon:linkedin', link: socialLinks?.linkedin, label: 'linkedin' },
                ]
                  .filter((v) => v.link)
                  .map((social, idx) => (
                    <Item sx={{ display: 'flex' }} key={idx}>
                      <Iconify icon={social.icon} color="#64b5f6" sx={{ flexShrink: 0, mr: 2 }} />
                      <a
                        href={`${social.link}` ?? '#'}
                        style={{ textDecoration: 'none', color: '#1976d2', fontWeight: 'bold' }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {social.link ? social.link : `Provide Social Links`}
                      </a>
                    </Item>
                  ))}

                {/* Address */}
                <Item sx={{ display: 'flex' }}>
                  <Iconify icon="solar:map-point-wave-bold-duotone" sx={{ mr: 2 }} />
                  <Tooltip title={branch?.address?.line1}>
                    <Typography
                      sx={{
                        maxWidth: '15rem',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {branch?.address?.line1 || 'No address provided'}
                    </Typography>
                  </Tooltip>
                </Item>
              </Stack>
            )}
          </Grid>

          {/* Availabilities Section */}
          <Grid item xs={12} md={8}>
            <Typography color="info" variant="h5" sx={{ mb: 2, ml: 2 }}>
              Availabilities
            </Typography>
            <Tabs
              sx={{ marginLeft: '-30px' }}
              value={currentTab}
              onChange={handleChangeTab}
              variant="scrollable"
              scrollButtons={true}
              allowScrollButtonsMobile
            >
              {avialableSlots?.map((tab, index) => (
                <Tab
                  key={tab.date}
                  label={
                    <Card
                      sx={{
                        mb: 1,
                        p: 1,
                        border: tab.isOpen ? '2px solid gray' : '2px solid red',
                        transition: 'border 0.3s',
                        cursor: 'pointer',
                      }}
                    >
                      <Stack spacing={0} alignItems="center">
                        <Typography variant="body2">
                          {moment(tab.date).format('dddd')} {/* Show day */}
                        </Typography>
                        <Typography variant="body2">
                          {moment(tab.date).format('MMMM D, YYYY')} {/* Show date */}
                        </Typography>
                      </Stack>
                    </Card>
                  }
                  value={index}
                  onClick={() => setCurrentTab(index)}
                />
              ))}
            </Tabs>

            {/* Display available times when a date is selected */}
            {avialableSlots[currentTab]?.slots?.length ? (
              <Box
                sx={{
                  height: '300px', // Set a fixed height, adjust as needed
                  overflowY: 'auto',
                  padding: '10px', // Enable vertical scrolling
                }}
              >
                <Stack
                  sx={{
                    mt: 2,
                  }}
                  spacing={2}
                >
                  {avialableSlots[currentTab]?.slots?.map((item, idx) => (
                    // <Card key={idx} sx={{ p: 2 }}>
                    //   {moment(item?.startTime).tz(timeZone).format('h:mm A')} -{' '}
                    //   {moment(item?.endTime).tz(timeZone).format('h:mm A')}
                    // </Card>
                    <Card
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 1,
                      }}
                    >
                      <Typography>
                        {moment(item?.startTime).tz(timeZone).format('h:mm A')} -{' '}
                        {moment(item?.endTime).tz(timeZone).format('h:mm A')}
                      </Typography>
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={() =>
                          window.open(
                            'https://play.google.com/store/apps/details?id=com.timezzicustomer',
                            '_blank'
                          )
                        }
                      >
                        <Tooltip title="Go to the app to make a booking" arrow>
                          Book Now
                        </Tooltip>
                      </Button>
                    </Card>
                  ))}
                </Stack>
              </Box>
            ) : (
              <Card sx={{ mt: 2 }}>
                <Typography variant="body2" sx={{ p: 2 }}>
                  No available times for this date.
                </Typography>
              </Card>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Box>
        <m.span variants={varFade().inUp}>
          <Box
            display={'flex'}
            rowGap={2}
            columnGap={2}
            marginTop="1rem"
            flexWrap={'wrap'}
            sx={{
              mt: 2,
              justifyContent: {
                lg: 'start',
                md: 'start',
                sm: 'center',
                xs: 'center',
              },
            }}
          >
            <DownloadButtons
              onClick={() => window.location.replace('/')}
              image={'/assets/icons/home/home.png'}
              title="Home"
              description="Go to Home"
            />
            <DownloadButtons
              image={'/assets/icons/app/apple.svg'}
              title="App Store"
              description="Download on the"
            />
            <DownloadButtons
              onClick={() =>
                window.open(
                  'https://play.google.com/store/apps/details?id=com.timezzicustomer',
                  '_blank'
                )
              }
              image={'/assets/icons/app/playstore.svg'}
              title="Play Store"
              description="Get it on"
            />
          </Box>
        </m.span>
      </Box>
    </Container>
  );
}
